<template>
  <div>
    <Overlay :overlay="overlay"/>
    <v-row class="mx-sm-8 mx-2">
      <v-col cols="12">
        <h1 class="mt-10">Usuarios</h1>
        <hr class="">
      </v-col>
      <v-col cols="3" sm="3" md="3" class="mt-10  ">
        <v-text-field v-model="searchData" label="Buscar"></v-text-field>
      </v-col>
      <v-col cols="6" class="mt-10  ">
      </v-col>

      <v-col cols="3" class="mt-10 ">
        <v-btn color="primary" :to="{ name: 'CreateUsers' }" style="width: 100%">
          Crear nuevo
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="users"
            :items-per-page="5"
            class="elevation-2"
        >
          <template v-slot:item.bloqueado="{ item }">
            <p class="mt-4" v-if="item.bloqueado">Si</p>
            <p class="mt-4" v-else>No</p>

          </template>
          <template v-slot:item.action="{ item }">
            <div class="d-flex">
              <div class="mr-2 card-icons-table">
                <router-link :to="{ name: 'ChangePassword', params: { id: item.id } }">
                  <v-icon medium color="black">mdi-magnify</v-icon>
                </router-link>
              </div>

              <div v-if="item.bloqueado" class="card-icons-table " style="cursor:pointer" @click="deleteData(item.id, false)">
                  <v-icon medium color="black">mdi-eye-off</v-icon>
              </div>
              <div v-else class="card-icons-table " style="cursor:pointer" @click="deleteData(item.id, true)">
                <v-icon medium color="black">mdi-eye</v-icon>
              </div>

            </div>

          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>

</template>

<script>


import {mapActions} from "vuex";
import Overlay from "@/components/Overlay.vue";

export default {
  name: "Users",
  components: {Overlay},
  data() {
    return {
      headers: [
        {text: 'id', value: 'id'},
        {text: 'Nombre', value: 'nombreCompleto'},
        {text: 'Email', value: 'email'},
        {text: 'Bloqueado', value: 'bloqueado'},
        {text: 'Acciones', value: 'action', sortable: false},
      ],
      users: [],
      searchData: null,
      overlay: false
    };

  },
  async created() {
    await this.findAll();

  },
  methods: {
    ...mapActions('users', ['findUsers', 'changeStatus']),
    async deleteData(id, status) {
      this.overlay = true;
      const send = {
        status
      }
      const response = await this.changeStatus({id: id, data: send});
      if (response.msg) {
        this.sweetAlertSuccess(response.msg)
        await this.findAll();
      } else {
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },
    async findAll() {
      const response = await this.findUsers();
      if (response.length > 0) {
        this.users = response;
        return
      }
      this.users = [];
    },

    download(item) {
    },
    search(item) {
    },
  },
}
</script>

<style scoped>
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: white;
}
</style>
